.modal-overlay {
	background-color: #00000030;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
}

.modal-content {
	max-width: 100%;
	min-height: 680px;
	backdrop-filter: blur(13px) saturate(180%);
	background-color: rgba(255, 255, 255, 0.12);
	border-bottom: 0px solid rgba(209, 213, 219, 0.1);
	box-shadow: inset 0px 0px 0px #fff9, inset 1px -0.5px 2px #ffffff4d, 0 0.6021873017743928px 0.6021873017743928px -1.25px #0000002e, 0 2.288533303243457px 2.288533303243457px -2.5px #00000029, 0 10px 10px -3.75px #00000010;
	border-radius: 0 0 30px 30px;
	transition: opacity 0.3s ease max-height 0.3s ease;

	
}

.modal-close-button {
	align-self: center;
}