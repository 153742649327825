.dropdown {
    position: absolute;
    top: 8px;
    right: 10px;
    background: #ffffff30;
    padding: 3px;
    border-radius: 30px;

}

.dropdown img {
    width: 30px;
    border-radius: 30px;
}

.dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    cursor: url('../assets/images/mouse.svg'), auto;
    position: relative;
    z-index: 99;
}

.dropdown-menu {
    cursor: url('../assets/images/mouse.svg'), auto;
    display: none;
    position: absolute;
    top: -2px;
    right: -3px;
    min-width: 230px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    backdrop-filter: blur(5px) saturate(160%);
    background-color: rgba(255, 255, 255, 0.12);
    border-bottom: 0px solid rgba(209, 213, 219, 0.1);
    box-shadow: inset 0px 1px 1px #fff5, inset 1px -0.5px 1px #ffffff4d, 0 0.6021873017743928px 0.6021873017743928px -1.25px #0000002e, 0 2.288533303243457px 2.288533303243457px -2.5px #00000029, 0 10px 10px -3.75px #00000010;
}


.dropdown-menu .status {
    margin: 0 0 8px 8px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.dropdown.open .dropdown-menu {
    display: block;
}

.dropdown-menu  {
  padding: 40px 10px 8px 10px;
}

.dropdown-menu li {
    list-style-type: none;
    
}

.dropdown-menu li a {
    text-decoration: none;
    display: block;
    padding: 8px;
    border-radius: 8px;
    margin: 3px 0;
    color: #fff;
}

.dropdown-menu li a:hover {
    background-color: #00000020;
    padding: 8px;
    display: block;
}

.dropdown-menu li a.active {
    color: #fff;
    background-color: #00000020;
    padding: 8px;
  }

.dropdown-menu li a.active:after {
    content: "😄";
    position: absolute;
    right: 16px;
}

.dropdown-menu li a:hover:after {
    content: "😂";
    position: absolute;
    right: 16px;
}

.dropdown-menu .status {
    border-radius: 8px;
    margin: 0;
    padding: 4px 8px;
    font-size: 16px;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.dropdown-menu .status:after {
    content: "😴";
    position: absolute;
    right: 16px;
}

.dropdown-menu .status:hover {
    background: linear-gradient(90deg, #ffb82160 0%, #ff5c3860 48%, #ff145960 100%);
}

.dropdown-menu .status:hover:after {
    content: "😮";
    position: absolute;
    right: 16px;
}

